exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-data-platform-js": () => import("./../../../src/pages/customer-data-platform.js" /* webpackChunkName: "component---src-pages-customer-data-platform-js" */),
  "component---src-pages-email-marketing-js": () => import("./../../../src/pages/email-marketing.js" /* webpackChunkName: "component---src-pages-email-marketing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-acceptableuse-js": () => import("./../../../src/pages/legal/acceptableuse.js" /* webpackChunkName: "component---src-pages-legal-acceptableuse-js" */),
  "component---src-pages-legal-antispam-js": () => import("./../../../src/pages/legal/antispam.js" /* webpackChunkName: "component---src-pages-legal-antispam-js" */),
  "component---src-pages-legal-cookies-js": () => import("./../../../src/pages/legal/cookies.js" /* webpackChunkName: "component---src-pages-legal-cookies-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-subprocessors-js": () => import("./../../../src/pages/legal/subprocessors.js" /* webpackChunkName: "component---src-pages-legal-subprocessors-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-marketing-automation-js": () => import("./../../../src/pages/marketing-automation.js" /* webpackChunkName: "component---src-pages-marketing-automation-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-tools-dkim-generator-js": () => import("./../../../src/pages/tools/dkim-generator.js" /* webpackChunkName: "component---src-pages-tools-dkim-generator-js" */),
  "component---src-pages-tools-dmarc-generator-js": () => import("./../../../src/pages/tools/dmarc-generator.js" /* webpackChunkName: "component---src-pages-tools-dmarc-generator-js" */),
  "component---src-pages-tools-subject-line-writer-js": () => import("./../../../src/pages/tools/subject-line-writer.js" /* webpackChunkName: "component---src-pages-tools-subject-line-writer-js" */),
  "component---src-pages-transactional-email-js": () => import("./../../../src/pages/transactional-email.js" /* webpackChunkName: "component---src-pages-transactional-email-js" */)
}

